import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, compose, Subscription } from "react-apollo";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// material
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { FaEdit } from "react-icons/fa";
import { MdAdd, MdCheckCircle, MdRemoveCircle } from "react-icons/md";

import {
  activeUser,
  deactivateUser,
  changeActivateListMerchants,
  forceConfirmEmail,
  forceUnconfirmEmail,
  addDealCoinsAdmin,
} from "./mutation";
import { listAllUsers } from "./queries";
import { Dialog } from "../../../../components";
import FilterFromToV2 from "../../../../components/Filter/FilterFromToV2";
import styles from "./styles";
import { colors } from "../../../../constants";
import { subUserEvent } from "../../Subscription";
import AddTDCDialogContent from "./AddTDCDialogContent";
import { TimerSharp } from "@material-ui/icons";
import { Grid } from "@material-ui/core";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class TableUser extends Component {
  state = {
    open: false,
    openAddTDC: false,
    title: "",
    content: "",
    search: "",
    page: 0,
    limit: 10,
    listCheckId: [],

    // filter
    email: "",
    dateFrom: "",
    dateTo: "",
    active: "",
    confirm: "",
    confirmTypeBalanceWallet: "",
    confirmTypeBalanceWalletMessage: "none",
    inputValBalance:0,
    dataUser:null,
  };

  onDataRefetch(){
    const { listRefetch } = this.props;
    listRefetch.refetch()
  }

  componentDidMount(){
    this.onDataRefetch()
  }

  componentDidUpdate(){
    this.onDataRefetch()
  }

  handleAddTDCClick = (dataUser) => {    
    this.setState({ openAddTDC: true, dataUser: dataUser });
  }

  renderAddTDCDialog = (data) => {
    return (
        <AddTDCDialogContent data={data} >
          <Grid>
          <Typography style={{ fontSize: 14 }} variant='caption'>{'Type of DealCoins'}</Typography>
            <Select
              name="confirmTypeBalanceWallet"
              onChange={this.handleFilterInpChange}
              value={this.state.confirmTypeBalanceWallet}
              displayEmpty
              style={{width:'100%'}}            
            >
              <MenuItem value={""}>
                <em>Select Type</em>
              </MenuItem>
              <MenuItem value={'tdc'}>Transaction DealCoins (TDC)</MenuItem>
              <MenuItem value={'edc'}>Earned DealCoins (EDC)</MenuItem>
            </Select>
          </Grid>
          <Grid style={{marginTop:10}}>
            <Typography style={{ fontSize: 14 }} variant='caption'>{'Add the amount'}</Typography>
            <TextField
                name="inputValBalance"
                value={this.state.inputValBalance}
                onChange={this.handleFilterInpChange}
                type="number"
                placeholder="Amount"
                style={{width:'100%'}}
              />
          </Grid>
          <Grid>
          <Typography style={{ fontSize: 14 }} variant='caption'>{'detail of the operation'}</Typography>
            <Select
              name="confirmTypeBalanceWalletMessage"
              onChange={this.handleFilterInpChange}
              value={this.state.confirmTypeBalanceWalletMessage}
              displayEmpty
              style={{width:'100%'}}            
            >
              <MenuItem value={'message_0'}>
                <em>Select message</em>
              </MenuItem>
              <MenuItem value={'message_1'}>Message 1</MenuItem>
              <MenuItem value={'message_2'}>Message 2</MenuItem>
            </Select>
          </Grid>
        </AddTDCDialogContent>
    )
  }

  onActive = (row) => {
    const { username } = row;
    const { activeUser } = this.props;
    activeUser({
      variables: {
        username,
      },
      refetchQueries: [{ query: listAllUsers }],
    })
      .then((res) => {
        if (res)
          this.setState({
            open: true,
            content: "Completed",
            title: "Active User",
          });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({
            open: true,
            content: graphQLErrors[0].message,
            title: "Active User",
          });
        }
      });
  };

  onOk = () => {
    this.setState({ open: false });
  };

  onOkAddTDC = () => {    
    const { wallet, username } = this.state.dataUser
    const { confirmTypeBalanceWallet, inputValBalance, confirmTypeBalanceWalletMessage } = this.state
    console.log(confirmTypeBalanceWalletMessage)
    const { addDealCoinsAdmin } = this.props;
    if(Number.parseInt(inputValBalance) === 0){
      this.setState({
        open: true,
        content: `The value is not allowed to be zero (0)`,
        title: "Add or remove Deal Coins",
      }); 
    }else{
      addDealCoinsAdmin({
        variables: {
          idWallet: wallet.id,
          type: confirmTypeBalanceWallet,
          amount: Number.parseInt(inputValBalance),
          detail: confirmTypeBalanceWalletMessage
        },
        refetchQueries: [{ query: listAllUsers }],
      })
        .then((res) => {
          let operation = Number.parseInt(inputValBalance) < 0?'removed':'added'
  
          if (res)
            this.setState({
              open: true,
              content: `${Math.abs(Number.parseInt(inputValBalance))} ${confirmTypeBalanceWallet.toUpperCase()} are ${operation} to the Wallet user ${username}`,
              title: "Add/Remove Deal Coins",
            });        
          this.setState({ openAddTDC: false, inputValBalance: 0, confirmTypeBalanceWallet: '', confirmTypeBalanceWalletMessage: '', dataUser: null });
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors) {
            this.setState({
              open: true,
              content: graphQLErrors[0].message,
              title: "Errors add Deal Coins",
            });          
            this.setState({ openAddTDC: false, inputValBalance: 0, confirmTypeBalanceWallet: '',confirmTypeBalanceWalletMessage: '', dataUser: null });
          }
        });
    }

      
  };

  onDeactive = (row) => {
    const { username } = row;
    const { deactivateUser } = this.props;
    deactivateUser({
      variables: {
        username,
      },
      refetchQueries: [{ query: listAllUsers }],
    })
      .then((res) => {
        if (res)
          this.setState({
            open: true,
            content: "Completed",
            title: "Deactive User",
          });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({
            open: true,
            content: graphQLErrors[0].message,
            title: "Deactive User",
          });
        }
      });
  };

  onChangeConfirm = (row) => {
    const { username, confirmed } = row;
    const { forceConfirmEmail, forceUnconfirmEmail } = this.props;
    if (confirmed) {
      forceUnconfirmEmail({
        variables: {
          username,
        },
        refetchQueries: [{ query: listAllUsers }],
      })
        .then((res) => {
          if (res)
            this.setState({
              open: true,
              content: "Completed",
              title: "Unconfirmed Email",
            });
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors) {
            this.setState({
              open: true,
              content: graphQLErrors[0].message,
              title: "Errors Unconfirmed Email",
            });
          }
        });
    } else {
      forceConfirmEmail({
        variables: {
          username,
        },
        refetchQueries: [{ query: listAllUsers }],
      })
        .then((res) => {
          if (res)
            this.setState({
              open: true,
              content: "Completed",
              title: "Confirmed Email",
            });
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors) {
            this.setState({
              open: true,
              content: graphQLErrors[0].message,
              title: "Errors Confirm Email",
            });
          }
        });
    }
  };

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page, listCheckId: [] });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, listCheckId: [] });
  };

  onSelectAllClick = (data) => {
    const { listCheckId } = this.state;
    if (listCheckId.length === 0) {
      data.forEach((item) => {
        listCheckId.push(item.id);
      });
    }
    this.setState({ listCheckId });
  };

  onSelectCheckbox = (row) => {
    let { listCheckId } = this.state;
    if (_.indexOf(listCheckId, row.id) < 0) {
      listCheckId.push(row.id);
    } else {
      listCheckId = _.filter(listCheckId, (item) => item !== row.id);
    }
    this.setState({ listCheckId });
  };

  onChangeListActivate = (status) => {
    const { listCheckId } = this.state;
    const { changeActivateListMerchants } = this.props;
    changeActivateListMerchants({
      variables: {
        listId: listCheckId,
        status,
      },
      refetchQueries: [{ query: listAllUsers }],
    })
      .then((res) => {
        if (res) {
          this.setState({
            open: true,
            content: "Completed",
            title: `${status ? "Active" : "Deactive"} User`,
            listCheckId: [],
          });
        }
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({
            open: true,
            content: graphQLErrors[0].message,
            title: `${status ? "Active" : "Deactive"} User`,
          });
        }
      });
  };

  handleFilterInpChange = (event) => {
    let { name, value } = event.target;
    if (value.toLowerCase) {
      value = value.toLowerCase();
    }

    this.setState({ [name]: value });
  };

  filter = (data) => {
    const { search, email, dateTo, dateFrom, active, confirm } = this.state;
    if (search)
      data = _.filter(data, (obj) =>
        obj.username.toLowerCase().match(search.toLowerCase())
      );

    if (email) {
      data = _.filter(data, (v) => v.email && v.email.includes(email));
    }

    if (active !== "") {
      data = _.filter(data, (v) => v.active === active);
    }

    if (confirm !== "") {
      data = _.filter(data, (v) => v.confirmed === confirm);
    }

    if (dateFrom) {
       data = _.filter(data, (v) =>
        moment(Number(v.createdAt)).isSameOrAfter(dateFrom, "day")
       );
    }

    if (dateTo) {
      data = _.filter(data, (v) =>
        moment(Number(v.createdAt)).isSameOrBefore(dateTo, "day")
      );
    }

    return data;
  };

  renderFilter = () => {
    const { classes } = this.props;
    const { search } = this.state;
    return (
      <div key="filter">
        <div className={classes.filterRow}>
          <FilterFromToV2
            label="Username"
            fromField={
              <TextField
                name="search"
                value={search}
                onChange={this.handleFilterInpChange}
                placeholder="Username"
              />
            }
            resetFn={() => this.setState({ search: "" })}
            smallButton
          />
          <FilterFromToV2
            label="Email"
            fromField={
              <TextField
                name="email"
                value={this.state.email}
                onChange={this.handleFilterInpChange}
                placeholder="Email"
              />
            }
            resetFn={() => this.setState({ email: "" })}
            smallButton
          />
          <FilterFromToV2
            label="Activation"
            fromField={
              <Select
                name="active"
                onChange={this.handleFilterInpChange}
                value={this.state.active}
                displayEmpty
              >
                <MenuItem value={""}>
                  <em>All</em>
                </MenuItem>
                <MenuItem value={true}>Activated</MenuItem>
                <MenuItem value={false}>Deactivated</MenuItem>
              </Select>
            }
            resetFn={() => this.setState({ active: "" })}
            smallButton
          />
          <FilterFromToV2
            label="Confirmation"
            fromField={
              <Select
                name="confirm"
                onChange={this.handleFilterInpChange}
                value={this.state.confirm}
                displayEmpty
              >
                <MenuItem value={""}>
                  <em>All</em>
                </MenuItem>
                <MenuItem value={true}>Confirmed</MenuItem>
                <MenuItem value={false}>Unconfirmed</MenuItem>
              </Select>
            }
            resetFn={() => this.setState({ confirm: "" })}
            smallButton
          />
        </div>
        <div className={classes.filterRow}>
          <FilterFromToV2
            label="Created Date"
            fromField={
              <TextField
                name="dateFrom"
                type="date"
                value={this.state.dateFrom}
                onChange={this.handleFilterInpChange}
                placeholder="From"
              />
            }
            toField={
              <TextField
                name="dateTo"
                type="date"
                value={this.state.dateTo}
                onChange={this.handleFilterInpChange}
                placeholder="To"
              />
            }
            resetFn={() => this.setState({ dateFrom: "", dateTo: "" })}
            smallButton
          />
        </div>
      </div>
    );
  };

  renderToolBarPagge = () => {
    const { classes } = this.props;
    const { listCheckId } = this.state;
    return (
      <div className={classes.searchWrapper}>
        {listCheckId.length > 0 ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonActive}
            onClick={() => this.onChangeListActivate(true)}
          >
            <Typography component="p" className={classes.text}>
              Activate
            </Typography>
          </Button>
        ) : null}
        {listCheckId.length > 0 ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonActive}
            onClick={() => this.onChangeListActivate(false)}
          >
            <Typography component="p" className={classes.text}>
              Deactivate
            </Typography>
          </Button>
        ) : null}
      </div>
    );
  };

  renderDialogChildren = () => {
    if (!this.state.openAddTDC) return null 
    return this.renderAddTDCDialog(this.state.dataUser)
  }

  render() {
    const { classes, listAllUsers } = this.props;
        
    const {
      title,
      content,
      open,
      openAddTDC,
      search,
      page,
      limit,
      listCheckId,
    } = this.state;

    let data = listAllUsers || [];

    data = _.orderBy(data, (obj) => obj.id, "desc");

    data = this.filter(data);

    data = _.map(data, (v) => ({
      ...v,
      createdAt: moment(Number(v.createdAt)),
    }));

    return (
      <div>
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        <Dialog
            title={'Add/Remove Deal Coins'}
            listChildren={this.renderDialogChildren()}
            open={openAddTDC}
            onOk={() => this.onOkAddTDC()}
            onCancel={() => this.setState({openAddTDC: false, inputValBalance: 0, confirmTypeBalanceWallet: ''})}
          />
        {this.renderFilter()}
        {this.renderToolBarPagge()}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell padding="checkbox">
                  <Checkbox
                    checked={
                      listCheckId.length ===
                      data.slice(page * limit, page * limit + limit).length
                    }
                    onChange={() =>
                      this.onSelectAllClick(
                        data.slice(page * limit, page * limit + limit)
                      )
                    }
                  />
                </CustomTableCell>
                <CustomTableCell>Username</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Email
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Role
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Wallet EDC/TDC
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Active
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.rowEdit}>
                  Edit
                </CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>
                  Active/Deactive
                </CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>
                  Confirm Email
                </CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>
                  Sign Up Date
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Desactivation Reason
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Delete Account
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map((row) => {
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell padding="checkbox">
                      <Checkbox
                        checked={_.indexOf(listCheckId, row.id) >= 0}
                        onChange={() => this.onSelectCheckbox(row)}
                      />
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {row.username}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {row.email}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.role}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTableBalance}>
                      {`${row.wallet.balance} / ${row.wallet.balanceTDC}`} 
                      {row.active &&
                        <MdAdd 
                          color={colors.dark}                        
                          size={20}
                          style={{cursor: 'pointer'}}
                          className={classes.btnBalance} 
                          onClick={() => {
                            this.handleAddTDCClick(row)
                          }}
                        />
                      }
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.active ? (
                        <MdCheckCircle color={colors.dark} size={16} />
                      ) : (
                        <MdRemoveCircle color={colors.red} size={16} />
                      )}
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      className={classes.columCenter}
                    >
                      <Link to={{ pathname: `/detailMerchant/${row.id}` }}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          <Typography component="p" className={classes.text}>
                            Edit
                          </Typography>
                          <FaEdit color="#ffffff" size={16} />
                        </Button>
                      </Link>
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      className={classes.columCenter}
                    >
                      {!row.active ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonActive}
                          onClick={() => this.onActive(row)}
                        >
                          <Typography component="p" className={classes.text}>
                            Activate
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonDeactive}
                          onClick={() => this.onDeactive(row)}
                        >
                          <Typography component="p" className={classes.text}>
                            Deactivate
                          </Typography>
                        </Button>
                      )}
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      className={classes.columCenter}
                    >
                      {!row.confirmed ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonActive}
                          onClick={() => this.onChangeConfirm(row)}
                        >
                          <Typography component="p" className={classes.text}>
                            Confirm
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonDeactive}
                          onClick={() => this.onChangeConfirm(row)}
                        >
                          <Typography component="p" className={classes.text}>
                            Unconfirm
                          </Typography>
                        </Button>
                      )}
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {row.createdAt.format("MM/DD/YYYY")}
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {row.deactivationReason}
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                    {!row.deleteAccount ? (
                        <MdRemoveCircle color={colors.red} size={16} />
                        ) : (
                        <MdCheckCircle color={colors.dark} size={16} />
                      )}
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Subscription
            subscription={subUserEvent}            
            onSubscriptionData={({ subscriptionData }) => {
              this.onDataRefetch()
            }}>          
          </Subscription>
        </Paper>
      </div>
    );
  }
}

// Mark: default props
TableUser.defaultProps = {
  classes: {},
  activeUser: () => {},
  deactivateUser: () => {},
  changeActivateListMerchants: () => {},
  listAllUsers: [],
  listRefetch: () => {},
  forceConfirmEmail: () => {},
  forceUnconfirmEmail: () => {},
  addDealCoinsAdmin: () => {},
};

// Mark: PropsType
TableUser.propTypes = {
  classes: PropTypes.object,
  activeUser: PropTypes.func,
  deactivateUser: PropTypes.func,
  changeActivateListMerchants: PropTypes.func,
  listAllUsers: PropTypes.array,
  listRefetch: PropTypes.func,
  forceConfirmEmail: PropTypes.func,
  forceUnconfirmEmail: PropTypes.func,
  addDealCoinsAdmin: PropTypes.func,
};

// Mark: Map data grapql to props
const ListAllUsers = graphql(listAllUsers, {
  props: ({ data }) => ({
    loadingList: data.loading,
    listAllUsers: data.listAllUsers
  }),
});
const Active = graphql(activeUser, { name: "activeUser" });
const listRefetch = graphql(listAllUsers, { name: "listRefetch"});
const Deactive = graphql(deactivateUser, { name: "deactivateUser" });
const ChangeActivate = graphql(changeActivateListMerchants, {
  name: "changeActivateListMerchants",
});
const ForceConfirmEmail = graphql(forceConfirmEmail, {
  name: "forceConfirmEmail",
});
const ForceUnconfirmEmail = graphql(forceUnconfirmEmail, {
  name: "forceUnconfirmEmail",
});
const AddDealCoinsAdmin = graphql(addDealCoinsAdmin, {
  name: "addDealCoinsAdmin",
});

export default withStyles(styles)(
  compose(
    ListAllUsers,
    listRefetch,
    Deactive,
    Active,
    ChangeActivate,
    ForceConfirmEmail,
    ForceUnconfirmEmail,
    AddDealCoinsAdmin
  )(TableUser)
);
